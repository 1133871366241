import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useFilterValues } from "contexts/filterValuesContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { handleErrorUseQuery } from "helpers/methods/handleErrorUseQuery";
import { createInputSettingAsync, deleteInputSettingAsync, getInputSettingsAsync, getInputSettingsByIdAsync, updateInputSettingAsync } from "services/chatbot/inputSettings";
import { FilterInputSettingType, IInputSettingsCreateModel, IInputSettingsUpdateModel } from "services/chatbot/types";

export function useGetInputSettingsList(params: Partial<FilterInputSettingType>) {
    const { currentTenantId } = useTenant();
    const { filterValues } = useFilterValues();
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    const filtersComplete = {
        ...filterValues.filters, 
        ...params, 
        tenant: currentTenantId
    }  as unknown as FilterInputSettingType;

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['input-settings-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const { data } = await getInputSettingsAsync(filtersComplete, token!);
            endRequest(true);
            return data;
        },
    });
};

export function useGetInputSettingsById(id: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    return useQuery({
        enabled: !!id && id !== "novo" && !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['input-settings-by-id', id],
        queryFn: async () => {
            startRequest();
            const { data } = await getInputSettingsByIdAsync(id, token!);
            endRequest(true);
            return data;
        },
    });
};

export function useCreateInputSettingsMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: IInputSettingsCreateModel) => {
            startRequest();
            const { data } = await createInputSettingAsync(values, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useUpdateInputSettingsMutation(
    id: string,
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();

    return useMutation({
        mutationFn: async (payload: IInputSettingsUpdateModel) => {
            startRequest();
            const { data } = await updateInputSettingAsync(id, payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useDeleteInputSettingsMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (id: string) => {
            startRequest();
            const { data } = await deleteInputSettingAsync(id, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}