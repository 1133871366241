import { chatbotAxiosApi } from "services/axiosApi/axiosApi";
import { IServiceQueueCreateModel } from "./types/CreateModel/ServiceQueueCreateModel";
import { IServiceQueueUpdateModel } from "./types/UpdateModel/ServiceQueueUpdateModel";
import { IServiceQueueReadModel } from "./types";

const url = `/ServiceQueue`;

export type ServiceQueueType = {
    tenant?: string
} 

export const getServiceQueueAsync = async (filters: ServiceQueueType, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params: filters
    }

    return await chatbotAxiosApi.get<IServiceQueueReadModel[]>(`${url}`, config);
}

export const getServiceQueueByIdAsync = async (id: string, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return await chatbotAxiosApi.get(`${url}/${id}`, config);
}

export const createServiceQueueAsync = async (payload: IServiceQueueCreateModel, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return await chatbotAxiosApi.post(`${url}`, payload, config);
}

export const updateServiceQueueAsync = async (id: string, payload: IServiceQueueUpdateModel, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return await chatbotAxiosApi.put(`${url}/${id}`, payload, config);
}
