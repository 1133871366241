import { ReactNode, useState } from 'react';
import { Popup, Button, Modal, Drawer } from '@uy3/web-components';
import * as creditNoteAPI from "services/creditNote";
import { Grid } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import CreditNoteListButtons from 'pages/CreditNote/CreditNoteList/CreditNoteButtonList/CreditNoteListButtons';
import { useQueryClient } from '@tanstack/react-query';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';
import { useNavigate } from 'react-router';
import { CreditNoteCnabContainer } from 'pages/CreditNote/CreditNoteList/CreditNoteCnab/CreditNoteCnabContainer';
import CreditNoteRejectComplianceContainer from 'pages/CreditNote/CreditNoteList/CreditNoteRejectCompliance/CreditNoteRejectComplianceContainer';
import { defaultValuesCancelForm, validationSchemaCancelForm } from 'pages/CreditNote/CreditNoteForm/Modals/Cancel/CancelSchema';
import { FormProvider } from 'contexts/formContext';
import { CancelForm } from 'pages/CreditNote/CreditNoteForm/Modals/Cancel/CancelForm';
import { FieldValues } from 'react-hook-form';
import GenerateAssignmentFormContainer from 'components/GenericForms/GenerateAssignmentForm/GenerateAssignmentFormContainer';
import { CreditNoteReadModel, GenerateAssignmentType } from 'services/creditNote/types/creditNoteReadModel';
import { mapErrorResponse } from 'contexts/responseErrorContext';

import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { RejectSignaturesValidation } from 'pages/CreditNote/CreditNoteForm/Modals/RejectSignaturesValidation/RejectSignaturesValidation';
import { ReprocessByStatusContainer } from 'pages/CreditNote/CreditNoteForm/Modals/ReprocessByStatus/ReprocessByStatusContainer';

interface CreditNoteListActionsProps {
    creditData: any;
    setSelectedStatusOfTable: React.Dispatch<React.SetStateAction<string[] | GridRowId[] | undefined>>
    selectedStatusOfTable: string[] | GridRowId[] | undefined;
    refetchCreditList: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    toast: ToastType
};

type PopupState = {
    title: string;
    message: string;
    children: ReactNode;
};

type ModalState = {
    title: string;
    description: string;
    children: JSX.Element;
};

type DrawerState = {
    title: string;
    children: JSX.Element;
};

export const CreditNoteListActions = ({
    creditData,
    selectedStatusOfTable: rowsSelected,
    refetchCreditList,
    setSelectedStatusOfTable,
    setToast,
    toast
}: CreditNoteListActionsProps) => {
    const navigate = useNavigate();
    const { token } = useIdentity();
    const queryClient = useQueryClient();
    const { currentTenantId } = useTenant();
    const { setSubmitError, endRequest, startRequest, submitting } = useApiRequest();
    const [popupProps, setPopupProps] = useState<PopupState | undefined>(undefined);
    const [modalProps, setModalProps] = useState<ModalState | undefined>(undefined);
    const [drawerProps, setDrawerProps] = useState<DrawerState | undefined>(undefined);
    const creditNote = creditData?.data;
    let getSelectedRow = creditNote?.filter((item: any) => rowsSelected?.includes(item?.id));

    const creditNoteId = getSelectedRow[0]?.id;

    const clearSelectedItems = () => setSelectedStatusOfTable([]);

    const handleErrorResponse = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        clearSelectedItems();
        endRequest(false);
    };

    const handleApprove = () => {
        getSelectedRow?.map(async (item: any) => {
            startRequest();
            await creditNoteAPI.approveCreditNoteById(item.id, token!, item.status, {})
                .then(() => {
                    const title = 'Operação aprovada com sucesso!'
                    const description = 'Ótimo! Agora você pode prosseguir com a operação.'
                    showSuccessToast(title, description, setToast);
                    queryClient.invalidateQueries({
                        queryKey: ["credit-note-list"],
                        refetchType: 'active',
                    });
                    refetchCreditList();
                    setPopupProps(undefined);
                    endRequest(true);
                    clearSelectedItems();
                }).catch((response: ApiResponseError) => handleErrorResponse(response));
        })
    };

    const approveliquid = () => {
        getSelectedRow?.map(async ({ id }: { id: string }) => {
            startRequest();
            await creditNoteAPI.approveliquidCreditNoteById(id, token!)
                .then(() => {
                    const title = 'Operação liquidação aprovada com sucesso!'
                    const description = 'Ótimo! Agora você pode prosseguir com a operação.'
                    showSuccessToast(title, description, setToast);

                    queryClient.invalidateQueries({
                        queryKey: ["credit-liquidation"],
                        refetchType: 'active',
                    });
                    refetchCreditList();
                    endRequest(true);
                    clearSelectedItems();
                }).catch((response: ApiResponseError) => handleErrorResponse(response));
        })
    };

    const sendApproval = async () => {
        startRequest();
        getSelectedRow?.map(async ({ id }: { id: string }) => {
            creditNoteAPI.submitApprovalCreditNoteById(id!, token!)
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ["credit-note-list"],
                        refetchType: 'active',
                    });
                    refetchCreditList();
                    clearSelectedItems();
                    endRequest(true);
                }).catch((response: ApiResponseError) => handleErrorResponse(response));
        });
    }

    const onGenerateAssignment = async (values?: GenerateAssignmentType) => {
        startRequest();
        let payload = {
            creditNoteIds: rowsSelected,
            ...values
        }
        if (payload?.assignmentCalculation === null) delete payload?.assignmentCalculation;
        creditNoteAPI.generateBatchAssignment(payload, token!)
            .then(() => {
                setModalProps(undefined);
                clearSelectedItems();
                endRequest(true);
                const title = 'Ação realizada com sucesso!'
                const description = 'Ótimo! agora você pode prosseguir com a operação.'
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const onDelete = () => {
        getSelectedRow?.map(async (item: CreditNoteReadModel) => {
            await creditNoteAPI.deleteCreditNoteById(item.id!, token!)
                .then(() => {
                    const title = 'Operação excluída com sucesso!'
                    const description = 'Ótimo! A lista de operações foi atualizada.';
                    showSuccessToast(title, description, setToast);
                    queryClient.invalidateQueries({
                        queryKey: ["credit-note-list"],
                        refetchType: 'active',
                    });
                    refetchCreditList();
                    clearSelectedItems();
                    endRequest(true);
                    setPopupProps(undefined);
                }).catch((response: ApiResponseError) => handleErrorResponse(response));
        })
    };

    const onCancel = (formValues: FieldValues) => {
        getSelectedRow?.map(async (item: CreditNoteReadModel) => {
            await creditNoteAPI.cancelCreditNoteById(formValues, item.id, token!)
                .then(() => {
                    const title = 'Operação cancelada com sucesso!'
                    const description = 'Excelente! Para atualizar o status da operação, clique em "Outras ações" e, selecione a opção "Definir status".';
                    showSuccessToast(title, description, setToast);
                    endRequest(true);
                    clearSelectedItems();
                    refetchCreditList();
                    setPopupProps(undefined);
                })
                .catch((response: ApiResponseError) => handleErrorResponse(response))
        })
    }

    const handleReprocessByStatus = async (creditNoteSequence: string) => {
        const ids = getSelectedRow?.map((item: CreditNoteReadModel) => item.id);
        try {
            await creditNoteAPI.postReprocessByStatus(creditNoteSequence, ids, token!);
            await refetchCreditList();
            endRequest(true);
            showSuccessToast("Esteira reiniciada com sucesso","",setToast);
            setModalProps(undefined);
        } catch (response) {
            return handleErrorResponse(response as ApiResponseError)
        };
    };

    const GenericComponentReject = () => {
        return <CreditNoteRejectComplianceContainer
            setPopupProps={setPopupProps}
            clearSelectedItems={clearSelectedItems}
            handleErrorResponse={handleErrorResponse}
            selectedPerson={getSelectedRow}
            refetch={refetchCreditList}
        />
    };

    const ActionsComponent = ({ method }: { method: any }) => {
        return <>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setPopupProps(undefined)}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={method}
                    disabled={submitting}
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </>
    };

    const CancelCreditNote = () => {
        return (
            <FormProvider
                validationSchema={validationSchemaCancelForm()}
                defaultValues={defaultValuesCancelForm}
                onChangeField={[{
                    fieldName: "insertBlock",
                    delegate: (
                        value: string | null,
                        setValue: (name: any, value: any) => void
                    ) => {
                        setValue("insertBlock", value)
                    }
                }]}
                onSubmit={onCancel}>
                <CancelForm
                    hasWarrantyStatus={getSelectedRow?.every((i: CreditNoteReadModel) => i.status.toLowerCase() === 'warranty')}
                    setPopupProps={() => setPopupProps(undefined)}
                    personInformation={{
                        discriminator:  getSelectedRow?.[0]?.personDiscriminator ?? '',
                        personId: getSelectedRow?.[0]?.personId ?? '',
                    }}
                />
            </FormProvider>
        )
    }

    const handleApproveSignature = async (reject: boolean, type: 'approveSignature' | 'signature') => {
        startRequest();
        getSelectedRow?.map(async (item: CreditNoteReadModel) => {
            creditNoteAPI
                .signatureCreditNoteById({ id: item?.id!, token: token!, reject, type })
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ['credit-note-list'],
                        refetchType: 'active',
                    });
                    showSuccessToast("Sucesso", "Validações de assinatura aprovada com sucesso", setToast)
                    endRequest(true);
                    setPopupProps(undefined);
                })
                .catch((response: ApiResponseError) => handleErrorResponse(response));
        })
    };

    return <>

        <Modal
            title={modalProps?.title}
            description={modalProps?.description}
            open={!!modalProps}
            onClose={() => setModalProps(undefined)}
            children={modalProps?.children}
            sizeModal="large"
        />

        <Popup
            open={!!popupProps}
            title={popupProps?.title}
            text={popupProps?.message}
            onClose={() => { setPopupProps(undefined); setToast({ ...toast, open: false }); }}
            children={popupProps?.children}
        />

        <Drawer
            title={drawerProps?.title}
            anchor='right'
            children={drawerProps?.children}
            open={!!drawerProps}
            onClose={() => setDrawerProps(undefined)}
        />

        <CreditNoteListButtons
            setToast={setToast}
            reprocessByStatus={() => setModalProps({
                title: 'Reiniciar Esteira',
                description: 'Selecione uma etapa para reiniciar esteira',
                children: (
                    <ReprocessByStatusContainer
                        handleSubmit={handleReprocessByStatus}
                        onClose={() => setModalProps(undefined)}
                    />
                ),
            })}
            refetch={refetchCreditList}
            selectedRow={getSelectedRow}
            onCreateCNAB={() => setDrawerProps({
                title: "Gerar relatório",
                children: <CreditNoteCnabContainer
                    onClose={() => setDrawerProps(undefined)}
                    creditNoteItemsSelected={getSelectedRow as CreditNoteReadModel[]}
                    rowsSelected={(rowsSelected as string[]) ?? []}
                />
            })}
            onCancel={() => setPopupProps({
                title: "Cancelar operação",
                message: "Tem certeza que deseja cancelar esse registro?",
                children: <CancelCreditNote />
            })}
            generateAssignment={() => setModalProps({
                title: "Iniciar cessão em lote",
                description: "",
                children: (
                    <GenerateAssignmentFormContainer
                        onGenerateAssignment={onGenerateAssignment}
                        onClose={() => setModalProps(undefined)}
                    />
                )
            })}
            sendApproval={() => setPopupProps({
                title: "Enviar para aprovação",
                message: "Tem certeza que deseja enviar para aprovação esse registro?",
                children: <ActionsComponent method={sendApproval} />
            })}
            complianceApprove={() => setPopupProps({
                title: 'Aprovar compliance',
                message: 'Você tem certeza que deseja aprovar este registro?',
                children: <ActionsComponent method={handleApprove} />
            })}
            rejactCompliance={() => setPopupProps({
                title: 'Rejeitar compliance',
                message: 'Você tem certeza que deseja rejeitar este registro?',
                children: <GenericComponentReject />
            })}
            creditApproval={() => setPopupProps({
                title: 'Aprovar crédito',
                message: 'Você tem certeza que deseja aprovar este registro?',
                children: <ActionsComponent method={handleApprove} />
            })}
            onDelete={() => setPopupProps({
                title: 'Excluir',
                message: 'Você tem certeza que deseja excluir este registro?',
                children: <ActionsComponent method={onDelete} />
            })}
            creditReject={() => setPopupProps({
                title: 'Rejeitar crédito',
                message: 'Você tem certeza que deseja rejitar este registro?',
                children: <GenericComponentReject />
            })}
            instrumentApproval={() => setPopupProps({
                title: 'Aprovar instrumento',
                message: 'Você tem certeza que deseja aprovar este registro?',
                children: <ActionsComponent method={handleApprove} />
            })}
            instrumentReject={() => setPopupProps({
                title: 'Rejeitar instrumento',
                message: 'Você tem certeza que deseja rejeitar este registro?',
                children: <GenericComponentReject />
            })}
            liquidationApprove={() => setPopupProps({
                title: 'Aprovar liquidação',
                message: 'Você tem certeza que deseja aprovar este registro?',
                children: <ActionsComponent method={approveliquid} />
            })}
            liquidationReject={() => setPopupProps({
                title: 'Rejeitar liquidação',
                message: 'Você tem certeza que deseja exluir este registro?',
                children: <GenericComponentReject />
            })}
            approveSignaturesValidation={() => setPopupProps({
                title: `Aprovar um total de ${getSelectedRow?.length ?? 0} operações em validação de assinaturas`,
                message: 'Você tem certeza que deseja aprovar esses registros selecionados?',
                children: <GenericActionHandler
                    isModal
                    handleSubmit={() => handleApproveSignature(false, 'approveSignature')}
                    onClose={() => setPopupProps(undefined)}
                />
            })}
            rejectSignaturesValidation={() => setPopupProps({
                title: `Reprovar um total de ${getSelectedRow?.length ?? 0} operações em validação de assinaturas`,
                message: 'Você tem certeza que deseja rejeitar esses registros selecionados?',
                children: <RejectSignaturesValidation
                    onErrorCatch={handleErrorResponse}
                    setPopupProps={setPopupProps}
                    onClose={() => setPopupProps(undefined)}
                    listOfIds={getSelectedRow ?? []}
                    statusCreditNote='SignaturesValidation'
                />
            })}
            clone={() => {
                creditNoteAPI.getCreditNoteById(creditNoteId, token!)
                    .then((result: any) => {
                        return creditNoteAPI.duplicateCreditNote(result.data, token!, currentTenantId)
                            .then(({ data }) => navigate(`/ccb/operacoes/${data}`))
                            .catch((response: ApiResponseError) => handleErrorResponse(response))
                    })
            }}
        />

    </>
};