import { activeConfig } from 'services/config';
import { BasicAmortization, currentDateWithSetHours, DefaultSchemaBasicAmortization, ValidationSchemaBasicAmortization } from './BasicAmortization';
import { string, object, date, number, DateSchema, boolean } from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { RequiredNumberSchema } from 'yup/lib/number';
import { handleNumericNaN } from 'helpers';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { ECalculationType, EPeriodicity, ECalculateByValueType } from 'utils/enums';

const appConfig = activeConfig();
const genericRequired = 'Precisa ser preenchido.';

let termValue = appConfig.DEFAULT_TERM_VALUE?.toString();

export abstract class SAC extends BasicAmortization {
    public calculateByValueType?: ECalculateByValueType | string | RequiredStringSchema<string | undefined, AnyObject>;
    public firstPaymentDate?: string | DateSchema<Date | undefined, AnyObject, Date | undefined>;
    public numberOfPayments?: number | RequiredNumberSchema<number | undefined, AnyObject>;
    public numberOfInterestPayments?: number | RequiredNumberSchema<number | undefined, AnyObject>;
    public calculationType?: ECalculationType | string | RequiredStringSchema<string | undefined, AnyObject>;
    public paymentPeriodicity?: EPeriodicity | string | any;
    public dueDateOnBusinessDays?: boolean;
}

export class DefaultSchemaAmmSAC extends DefaultSchemaBasicAmortization implements SAC {
    public amortizationType = 'sac';
    public calculateByValueType: ECalculateByValueType | string = ECalculateByValueType[ECalculateByValueType.Gross];
    public firstPaymentDate = toIsoStringWithTimezone(new Date(currentDateWithSetHours));
    public numberOfPayments = parseInt(termValue);
    public numberOfInterestPayments = 0;
    public calculationType: ECalculationType | string = ECalculationType[ECalculationType.V365Meses];
    public paymentPeriodicity = {
        every: 1,
        periodicity: EPeriodicity[EPeriodicity.Monthly]
    };
    public dueDateOnBusinessDays = false;
    public discountMode = false;
}

export class ValidationSchemaAmmSac extends ValidationSchemaBasicAmortization {
    public calculateByValueType = string()
        .oneOf(['Gross', 'Liquid', 'Payment'], 'Selecione um tipo de cálculo')
        .required('Tipo de cálculo é obrigatório.');
    public firstPaymentDate = date()
        .typeError('Data inválida')
        .required('Data do primeiro pagamento é obrigatória.');
    public numberOfPayments = number()
        .typeError('Valor precisa ser um número.')
        .required(`Número de pagamentos: ${genericRequired}`);
    public numberOfInterestPayments = number()
        .typeError('Valor precisa ser um número.').required(`Parcelas de juros: ${genericRequired}`);
    public calculationType = string()
        .oneOf(calculationTypeList, 'Selecione um tipo de cálculo')
        .typeError('Selecione um tipo de cálculo')
        .required(`Tipo de cálculo: ${genericRequired}`);
    public paymentPeriodicity = object().shape({
        every: number()
            .required(`Pagamento: ${genericRequired} `)
            .min(1, 'Valor mínimo: 1')
            .transform(handleNumericNaN),
        periodicity: string()
            .oneOf(['Daily', 'Monthly', 'Yearly'], 'Selecione uma periodicidade')
            .required(`Periodicidade: ${genericRequired} `),
    });
    public dueDateOnBusinessDays = boolean().default(false);
    public discountMode = boolean().default(false);
}

const calculationTypeList = [
    'V252DiasUteis',
    'V252MesesX21',
    'V360DiasCorridos',
    'V360Meses',
    'V365DiasCorridos',
    'V365Meses'
];
